// import { Link } from 'gatsby'
import React from 'react'
// import Logo from '../utils/logo'
import BgVid from '../../images/vid/splash.mp4'
import Back from '../../images/back.svg'
import LogoSVG from '../../images/aaha.svg'
import scrollTo from 'gatsby-plugin-smoothscroll';


 const Welcome = ({text}) => {

    return (
        <div className="splash">
            <div className="hello">
                <img className="the_logo" src={LogoSVG} alt='Aaha!'/>
                <h1>{text}</h1>

                <span className="muted" role='button' onClick={() => scrollTo('#start')}><img src={Back} alt='Click here to get started'/></span>
            </div>
            <video className="home-vid" src={BgVid} autoPlay loop playsInline muted />
        </div>
    )
}

export default Welcome
