import * as React from "react"
import '../styles/main.scss'
import Layout from "../components/layouts/layout";
import { SeoC } from "../components/utils/seo";
import Portfolio from "../components/portfolio/portfolio";
import Welcome from "../components/home/home";
import { graphql } from "gatsby";

// markup
const IndexPage = ({data}) => {

  const allWork = data.allDatoCmsWork.edges;

  return (
    <Layout>
      <div className="home">
        <Welcome text={data.site.siteMetadata.welcome}/>
        <Portfolio videos={allWork} />
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SeoC />
)

export default IndexPage

export const AllWorkQuery = graphql`
  query {
    allDatoCmsWork(sort: {fields: sortOrder, order: ASC}) {
    edges {
      node {
        title
        videoFile
        agency
        client
        director
        id
        slug
        typeOfFilm
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }
  site {
      siteMetadata {
          title
          siteUrl
          description
          welcome
      }
    }
  }
`;

